body {
  margin: 0;
  font-family: 'Rubik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.leaflet-container,
.leaflet-pane:not(.leaflet-popup-pane) {
  z-index: unset;
}

.leaflet-popup-content-wrapper {
  background: none;
  box-shadow: none;
  color: unset;
  border-radius: 0;
}

.leaflet-popup-content {
  margin: 0;
}

.leaflet-popup-tip-container,
.leaflet-popup-close-button {
  display: none;
}

.leaflet-div-icon {
  background: none;
  border: none;
}

.leaflet-marker-icon {
  z-index: 2 !important;
}

.leaflet-popup-content p {
  margin: 0;
}

.leaflet-control-attribution {
  bottom: 0;
  right: 0;
}

.dark-theme .leaflet-tile-pane,
.dark-theme .leaflet-control-attribution {
  -webkit-filter: invert(1) grayscale(1);
  filter: invert(1) grayscale(1);
}

svg.marker text {
  font-family: 'Rubik';
  font-weight: 500;
}

[data-theme='dark'] *::-webkit-scrollbar {
  width: 0.35em;
}

[data-theme='dark'] *::-webkit-scrollbar-track {
  background: #000000;
}

[data-theme='dark'] *::-webkit-scrollbar-thumb {
  background-color: #333333;
}

[data-theme='light'] *::-webkit-scrollbar {
  width: 0.35em;
}

[data-theme='light'] *::-webkit-scrollbar-track {
  background: #f6f6f6;
}

[data-theme='light'] *::-webkit-scrollbar-thumb {
  background-color: #cccccc;
}

.button {
  -webkit-font-smoothing: antialiased;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-family: Rubik;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 8px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  cursor: pointer;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.2, 0.8, 0.4, 1);
  outline: none;
  background-color: rgb(0, 0, 0);
  padding-left: 8px;
  font-weight: 500;
  margin-top: 0px;
  margin-right: 0px;
  display: inline-flex;
  border-left-width: 0px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-style: none;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  outline-offset: -3px;
  text-decoration: none;
  appearance: none;
  transition-property: background;
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 8px;
  margin-left: 10px;
  color: rgb(255, 255, 255);
  position: absolute;
  right: 20px;
  bottom: 60px;
}